import { ArrowUpRight } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';

// import profile1 from '../../../../assets/img/Avatar1.png';
import CustomPagination from '../../../../components/CustomPagination';
import NotFoundUI from '../../../../components/NotFoundUI';

import TeamPerformanceDetailTable from './TeamPerformanceDetailTable';
import TeamPerformanceLoadingRow from './TeamPerformanceLoadingRow';
import TeamPerformanceRow from './TeamPerformanceRow';

interface IProps {
  teamPerformanceData: any;
  isDataLoading: boolean;
  activeFilter: string;
  userList: any;
  dataObj: any;
  setSelectedUser: any;
  selectedUser: string | undefined;
  currentFilterType: string;
}

const recordsPerPage = 9;

const TeamPerformanceTable = ({
  selectedUser,
  setSelectedUser,
  userList,
  currentFilterType,
  teamPerformanceData,
  isDataLoading,
}: IProps) => {
  const [filteredTeamPerformanceList, setFilteredTeamPerformanceList] =
    useState<any[]>([]);
  const [sortData, setSortData] = useState({
    sortField: 'name',
    sortType: 'asc',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowGraphPopup, setIsShowGraphPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageItem, setPageItem] = useState<any[]>([]);
  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'User',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'totalMargin',
        name: 'Total Margin',
        rowClassName: 'w-32 ',
        sortable: true,
      },
      {
        id: 'orderCount',
        name: `Orders`,
        rowClassName: 'w-28 text-center text-grayLight600 ',
        sortable: true,
      },
    ],
    [currentFilterType]
  );

  useEffect(() => {
    setIsLoading(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    const teamPerformanceDataWithAllUsers: any[] = [];
    const availableUserIds = teamPerformanceData
      .map((e: any) => e.userId)
      .filter((e: number) => e);

    for (const userData of userList) {
      const lastSlashIndex = userData?.image?.lastIndexOf('/');
      const imageUrl = userData?.image?.substring(0, lastSlashIndex + 1);
      const image = userData?.image?.substring(lastSlashIndex + 1);

      if (!availableUserIds.includes(userData?.value)) {
        teamPerformanceDataWithAllUsers.push({
          userId: userData?.value,
          name: userData?.label,
          image: image,
          imageUrl: imageUrl,
          totalMargin: 0,
          orderCount: 0,
          marginPercentage: 0,
          averageDailyMargin: 0,
          customerCount: 0,
          newCustomers: 0,
        });
      }
    }

    if (selectedUser !== 'all') {
      if (teamPerformanceData?.length > 0) {
        setFilteredTeamPerformanceList([...teamPerformanceData]);
      } else {
        const selectedUserWholeData = userList.find(
          (uld: any) => uld.value === selectedUser
        );

        if (selectedUserWholeData) {
          setFilteredTeamPerformanceList([
            ...teamPerformanceData,
            {
              name: selectedUserWholeData?.label,
              totalMargin: 0,
              orderCount: 0,
              marginPercentage: 0,
              averageDailyMargin: 0,
              customerCount: 0,
              newCustomers: 0,
              userId: 0,
              image: null,
              imageUrl: null,
            },
          ]);
        } else {
          setFilteredTeamPerformanceList([...teamPerformanceData]);
        }
      }
    } else {
      setFilteredTeamPerformanceList([
        ...teamPerformanceData,
        ...teamPerformanceDataWithAllUsers,
      ]);
    }
    setCurrentPage(1);
  }, [userList, teamPerformanceData]);

  const handleSorting = () => {
    const { sortField, sortType } = sortData;

    const sortedCustomerList = filteredTeamPerformanceList.sort(
      (a: any, b: any) => {
        if (sortField === 'name') {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (sortType === 'asc') {
            return nameA.localeCompare(nameB);
          }

          return nameB.localeCompare(nameA);
        } else {
          if (a[sortField] < b[sortField]) {
            return sortType === 'asc' ? -1 : 1;
          }

          if (a[sortField] > b[sortField]) {
            return sortType === 'asc' ? 1 : -1;
          }
        }

        return 0;
      }
    );

    setFilteredTeamPerformanceList([...sortedCustomerList]);
  };

  useEffect(() => {
    // setIsLoading(true);
    setPageItem([]);

    if (filteredTeamPerformanceList.length > recordsPerPage) {
      const pageItems = filteredTeamPerformanceList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredTeamPerformanceList]);
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
    // setIsLoading(false);
  }, [filteredTeamPerformanceList, currentPage, recordsPerPage]);

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="w-full p-4 justify-between items-center flex flex-wrap gap-2">
          <div className="gap-1 flex flex-col">
            <div className="text-grayLight900 text-base font-semibold leading-7 ">
              Team Performance
            </div>
          </div>
          {/* <div className="gap-2 flex items-center">
            <img
              className="w-5 h-5 rounded-full object-cover flex-none"
              src={profile1}
              alt=""
            />
            <div className="text-grayLight900 text-sm font-semibold leading-7 ">
              Team Name
            </div>
          </div> */}
          {selectedUser === 'all' && (
            <div className="gap-4 flex flex-col">
              <div
                className="flex text-xs items-center text-primary font-semibold cursor-pointer"
                onClick={() => setIsShowGraphPopup(true)}
              >
                View All <ArrowUpRight className="h-4 w-4" />
              </div>
            </div>
          )}
        </div>
        <div className="w-full bg-white border-t border-gray100 overflow-x-auto custom-scrollbar scrollbar-hide">
          <TableCmp
            params={sortData}
            setParams={setSortData}
            headCells={headCells}
            tableDataArr={isLoading ? [] : pageItem}
            TableRowCmp={TeamPerformanceRow}
            TableLoaderRowCmp={TeamPerformanceLoadingRow}
            tableHeaderClass=""
            isTableDataLoading={isLoading}
            isTableRowClickable={true}
            numberOfSkeletonRows={recordsPerPage}
            tableRowCmpProps={{
              currentFilterType: currentFilterType,
              userList: userList,
              setSelectedUser: setSelectedUser,
            }}
          />
        </div>

        {!isLoading && !filteredTeamPerformanceList.length && (
          <NotFoundUI
            title="No Team Performance Found"
            desc="There are no data for team member performance related data."
            containerClassName="my-0 flex-1"
          />
        )}
      </div>

      <div className="w-full">
        {!isLoading && (
          <CustomPagination
            recordsPerPage={recordsPerPage}
            totalRecords={filteredTeamPerformanceList.length}
            currentPage={currentPage}
            handlePagination={setCurrentPage}
            nextLabelText=""
            prevLabelText=""
            isShowArrowAlways={true}
          />
        )}
      </div>

      {isShowGraphPopup && (
        <TeamPerformanceDetailTable
          isLoading={isLoading}
          filteredTeamPerformanceList={filteredTeamPerformanceList}
          handleClose={() => setIsShowGraphPopup(false)}
          currentFilterType={currentFilterType}
        />
      )}
    </>
  );
};

export default TeamPerformanceTable;
