export const API = {
  USER: {
    LOGIN: '/user/login',
    LOGOUT: '/user/logout',
    CODE: '/user/code',
    GET: '/user/get',
    LIST: '/user',
    CREATE: '/user',
    UPDATE: '/user',
    DELETE: '/user',
    PERMISSION: '/user/permissions',
    GET_DETAIL: '/user/get-detail',
    REMOVE_FROM_TEAM: '/user/remove-from-team',
  },
  TEAM: {
    LIST: '/team',
    LIST_ALL: '/team/list',
    CREATE: '/team/create',
    UPDATE: '/team/update',
    DELETE: '/team',
    DETAIL: '/team',
    ORG_CHART: '/ORGANIZATIONAL-chart',
  },
  CARRIER: {
    LIST: '/carrier',
    CREATE: '/carrier',
    UPDATE: '/carrier',
    DELETE: '/carrier',
    UPDATE_IMAGE: '/carrier/image',
    GET_SERVICE_PRICES: '/carrier-service-prices',
    UPDATE_SERVICE_PRICES: '/carrier-service-prices',
  },
  CARRIER_CONTACT: {
    LIST: '/carrier-details',
    CREATE: '/carrier-details',
    UPDATE: '/carrier-details',
    DELETE: '/carrier-details',
  },
  QUOTE_EMAIL: {
    GET: '/quote-email',
    SEND_REPLY: '/send-reply',
    SET_EMAIL_READ: '/set-message-read',
    FETCH_EMAIL_DETAILS: '/fetch-email-details',
    GET_OUTLOOK_INTEGRATION: '/get-outlook-integration',
    INTEGRATE_OUTLOOK: '/integrate-outlook',
    DELETE_QUOTE_EMAIL: 'quote-email/delete',
  },
  QUOTE: {
    GET: '/quote',
    GET_USER_QUOTES: '/user/quotes',
    LIST: '/quote',
    CREATE: '/quote/address',
    UPDATE: '/quote/address',
    CREATE_UPDATE_DIMENSION: '/quote/dimension',
    CREATE_UPDATE_SERVICE: '/quote/service',
    CREATE_UPDATE_SUMMARY: '/quote/summary',
    CREATE_RATE: '/quote/rate',
    UPDATE_RATE: '/quote/rate',
    CANCEL: '/quote/cancel',
    CREATE_PDF: '/quote/pdf',
    UPDATE_PDF: '/quote/pdf',
    LIST_PDF: '/quote/pdf',
    UPDATE_STATUS: '/quote/status',
    PUSH_TO_ROSE_ROCKET: '/quote/roserocket-order',
    DELETE: '/quote',
    MODIFY_QUOTE: '/quote/modifyquote',
    SPOT_QUOTE: '/quote/spot-quote-request',
    SPOT_QUOTE_UPDATE: '/quote/update-spot-quote',
    GET_FREIGHT_CLASS: '/quote/dimension/get-freight-class',
    GET_PREDICTIVE_PRICING: '/quote/predictive-pricing',
  },
  SHIPPER: {
    LIST: '/shipper',
    CREATE: '/shipper',
    DELETE: '/shipper',
  },
  CUSTOMER: {
    LIST: '/customer',
    UPDATE_CUSTOMER: '/customer/update-customer',
    TOP_CUSTOMERS: '/top-customers',
    GET: 'customer-list',
    DETAIL: 'customer-detail',
    SEGMENT: 'customer-segment-total',
    DELETE: '/customer',
    UPDATE_IMAGE: '/customer/image',
    ORDER_KEY_METRICS: 'customer-detail/key-metrics',
    LIST_SALES_REP_HISTORY: '/customer/sales-rep-history',
    SALES_REP: 'customer/sales-rep',
    UPDATE_SALES_REP: 'customer/update-sales-rep',
    REASSIGN_SALES_REP: 'customer/reassign-sales-rep',
    CONTACTS: {
      GET: '/customer/contacts',
      LIST: '/customer/contacts',
    },
    CREDIT: {
      CREDIT_ORDER_LIST: '/customer/list-credit-orders',
    },
  },

  HANDLING_UNIT: {
    LIST: '/handling-unit',
  },
  ROLE: {
    LIST: '/role',
  },
  PERMISSION: {
    LIST: '/permissions',
  },
  SERVICES: {
    LIST: '/service',
  },
  CURRENCY_RATE: {
    GET: '/currency/rate',
  },
  FEEDBACK: {
    CREATE: '/user/feedback',
    GET: '/user/feedback',
    UPDATE_STATUS: '/user/feedback-status',
  },
  SALES_DASHBOARD: {
    UPLOAD: '/sales/upload-data',
    ASSIGN_SALES_REPS: '/sales/assign-sales-reps',
    UPLOAD_LIST: '/sales/get-upload-history',
    TEAM_PERFORMANCE_LIST: '/sales/team-performance-list',
    SALES_DATA: '/sales/get-data',
    SALES_ANALYTICS_DATA: '/sales/get-sales-data',
    SALES_GRAPH: '/sales/sales-graph-data',
    INDIVIDUAL_GRAPH: '/sales/graph-data',
    TEAM_LIST: 'sales/team-list',
    DELETE_DATA: 'sales/reset-data',
    DOWNLOAD_FILE: 'sales/download-file',
    CONFIRM_UPLOAD: 'sales/confirm-upload-data',
    SEND_DOWNLOAD_OTP: '/sales/send-download-otp',
    VERIFY_DOWNLOAD_OTP: '/sales/verify-download-otp',
    LAST_SYNC: '/sales/upload-detail',
    CHECK_PENDING_UPLOAD: '/sales/check-pending-upload',
    LEADERBOARD_RANKING: '/leaderboard/list',
    LEADERBOARD_RANKING_NEW_CUSTOMERS: '/leaderboard/customer-to-process',
    LEADERBOARD_RANKING_MY_NEW_CUSTOMERS:
      '/leaderboard/leaderboard-my-new-customers',
    LEADERBOARD_RANKING_NEW_CUSTOMERS_CHANGE_STATUS:
      '/leaderboard/update-customer-consideration-status',
    ONGOING_EVENT_DETAIL: '/leaderboard/fetch-ongoing-event-details',
    LEADERBOARD_ACTIVITIES: '/leaderboard/activity',
  },
  ORDERS: {
    GET_UNASSIGNED_ORDERS_BY_FILE: '/orders/get-unassigned-orders-by-file',
    LIST: '/orders/get-orders-list',
  },
  INTEGRATIONS: {
    LIST: '/integrations',
    TYPE: '/integrations/type',
    MAP_SERVICE: '/integrations/map-service',
    SET_CONFIGURATIONS: '/integrations/configurations',
    ENABLE_INTEGRATION: '/integrations/enable',
  },

  TARGET: {
    LIST: '/sales/target-list',
    SET: '/sales/set-target',
    CUSTOMER_LIST: '/sales/customer-list',
  },
};
