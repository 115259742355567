import { ArrowDown, ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useRef, useState } from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getShortName,
  setNumberWithCommas,
} from 'src/utils/CommonFunctions';

import trophy1 from '../../../assets/img/rank-1.png';
import trophy2 from '../../../assets/img/rank-2.png';
import trophy3 from '../../../assets/img/rank-3.png';
import fireBadge from '../../../assets/img/streaks.svg';
import winnerFlag from '../../../assets/img/winner-flag.png';

const LeaderRankRow = ({ data }: any) => {
  const nameRef = useRef<HTMLHeadingElement>(null);
  const [isNameTruncated, setIsNameTruncated] = useState(false);

  useEffect(() => {
    if (nameRef.current) {
      setIsNameTruncated(
        nameRef.current.scrollWidth > nameRef.current.clientWidth
      );
    }
  }, []);

  return (
    <>
      <td className="px-5 py-4 w-[8%] min-w-[100px] max-w-[8%]">
        <span>{data.ranking}</span>
      </td>

      <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
        {/* IF NEED RED COLORED GIVE "text-fgErrorPrimary" IN BELOW DIV AND REMOVE "-" FROM "-rotate-90" IN ICON*/}
        <div
          className={`flex items-center gap-x-1.5 font-semibold ${
            data.rankingChange < 0
              ? 'text-fgErrorPrimary'
              : data.rankingChange > 0
              ? 'text-fgSuccessPrimary'
              : ''
          }`}
        >
          {data.rankingChange < 0 && (
            <>
              <ArrowDown className="w-4 h-4" />-
            </>
          )}
          {data.rankingChange > 0 && (
            <>
              <ArrowUp className="w-4 h-4" />+
            </>
          )}
          {Math.abs(data.rankingChange)}
        </div>
      </td>

      <td className="px-5 py-4 w-[8%] min-w-[200px] max-w-[8%]">
        <div className="flex items-center gap-2">
          {data.profileImage ? (
            <img
              className="table-profile-icn-circle-sm"
              src={data.profileImage}
            />
          ) : (
            <div className="table-profile-user-circle-sm">
              <span>{getShortName(`${data.firstName} ${data.lastName}`)}</span>
            </div>
          )}
          <div className="truncate">
            <TooltipCmp
              message={
                isNameTruncated ? `${data.firstName} ${data.lastName}` : ''
              }
            >
              <h6
                ref={nameRef}
                className="text-grayLight900 font-medium leading-tight truncate"
              >
                {data.firstName} {data.lastName}
              </h6>
            </TooltipCmp>
          </div>
          {data.ranking == 1 && data.totalPoints > 0 && (
            <>
              <img src={trophy1} className="w-[15px] h-[15px] object-contain" />
              <img
                src={winnerFlag}
                className="w-[15px] h-[15px] object-contain flex-none"
              />
            </>
          )}
          {data.ranking == 2 && data.totalPoints > 0 && (
            <img src={trophy2} className="w-[15px] h-[15px] object-contain" />
          )}
          {data.ranking == 3 && data.totalPoints > 0 && (
            <img src={trophy3} className="w-[15px] h-[15px] object-contain" />
          )}
          {data.oldRanking - data.ranking >= 3 && (
            <img src={fireBadge} className="w-[15px] h-[15px] object-contain" />
          )}
        </div>
      </td>

      <td className="px-5 py-4 w-[14%] min-w-[150px] max-w-[14%]">
        <span>
          {data.totalCustomers}{' '}
          {data.totalCustomers == 1 ? 'Customer' : 'Customers'}
        </span>
      </td>

      <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
        <span>
          {data.daysInLead} {data.daysInLead == 1 ? 'Day' : 'Days'}
        </span>
      </td>

      <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
        <span>
          {data.profitBooked
            ? `$${setNumberWithCommas(
                getFormattedNumber(data.profitBooked, false, true)
              )}`
            : ''}
        </span>
      </td>

      <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
        <span>
          {data.profitDelivered
            ? `$${setNumberWithCommas(
                getFormattedNumber(data.profitDelivered, false, true)
              )}`
            : ''}
        </span>
      </td>

      <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
        <span>{data.totalPoints}</span>
      </td>
    </>
  );
};

export default LeaderRankRow;
