import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { TIMEZONE, USER_ROLE } from 'src/constants/common';

import Bronze from '../assets/img/customer-rank-diamonds/bronze.svg';
import Diamond from '../assets/img/customer-rank-diamonds/diamond.svg';
import Gold from '../assets/img/customer-rank-diamonds/gold.svg';
import Platinum from '../assets/img/customer-rank-diamonds/platinum.svg';
import Silver from '../assets/img/customer-rank-diamonds/silver.svg';
import defaultImage from '../assets/img/default-image.jpg';
import { MEASUREMENT, REGION_CODE } from '../constants/common';
import { AuthContext } from '../context/AuthContext';
import { downloadGCSPdf } from '../services/QuoteService';

export const preventEnterSubmit = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const getSelectBoxOptions = (
  data: Array<any>,
  value: string = 'id',
  label: string = 'name',
  isImage: boolean = false,
  imageUrl: string = 'imageUrl',
  image: string = 'image',
  members: string = 'members',
  secondLabel: any = null,
  isSort: boolean = false,
  defaultImg: any = null
) => {
  if (!Array.isArray(data)) {
    data = [data];
  }
  if (isSort) data.sort((a, b) => a[label].localeCompare(b[label]));

  return data.map((dataItem: any) => ({
    value: dataItem[value],
    label: `${dataItem[label]} ${secondLabel ? dataItem[secondLabel] : ''}`,
    members: dataItem[members],
    image: isImage
      ? dataItem[image]
        ? dataItem[imageUrl] + dataItem[image]
        : defaultImg
      : defaultImg,
    isImage: isImage,
  }));
};

export const getShortName = (name: string = '') => {
  const shortName = name
    .split(' ')
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join('');

  return shortName;
};

export const onError = (event: any) => {
  event.target.src = defaultImage;
};

export const useRolePermission = () => {
  const { currentUser } = useContext(AuthContext);

  const hasRoleV2 = (roleName: string) => {
    if (
      !currentUser?.role?.name ||
      !Object.values(USER_ROLE).includes(roleName)
    ) {
      return false;
    }

    if (roleName === USER_ROLE.USER) {
      return [USER_ROLE.SALES, USER_ROLE.OPERATIONS, USER_ROLE.CLAIMS].includes(
        currentUser?.role?.name
      );
    }

    return currentUser?.role?.name === roleName;
  };

  const hasPermissionV2 = (permission: string) => {
    const permissionsNameList = currentUser.permissions.map(
      (curPermission: any) => curPermission.name
    );

    return permissionsNameList.includes(permission);
  };

  // old one
  const hasRole = (roleName: string) => (roleName ? true : false);

  // const permissions = currentUser.role.permissions.split(',');
  // return permissions.includes(permission);
  // TODO : implement role and permission management
  const hasPermission = (permission: string) => (permission ? true : false);

  return { hasRoleV2, hasRole, hasPermission, hasPermissionV2 };
};

export const usePhone = () => {
  const setFormatPhone = (phone: string) => {
    try {
      if (phone) {
        const number = parsePhoneNumber(phone, REGION_CODE);

        return number.isValid() ? number.formatNational() : phone;
      }

      return phone;
    } catch {
      return phone;
    }
  };

  const getFormatPhone = (phone: string) => {
    phone = phone && phone.includes(',') ? phone.split(',')[0] : phone;

    try {
      const number = parsePhoneNumber(phone, REGION_CODE);
      let formattedNumber = phone;

      if (number.isValid()) {
        formattedNumber =
          number.getType() === 'TOLL_FREE'
            ? number.formatInternational()
            : number.formatNational();
      }

      return formattedNumber
        .replace('+', '')
        .replace('(', '')
        .replace(')', '')
        .split(' ')
        .join('-');
    } catch {
      return phone;
    }
  };

  const getPhone = (phone: string) => {
    phone = phone && phone.includes(',') ? phone.split(',')[0] : phone;

    try {
      const number = parsePhoneNumber(phone, REGION_CODE);

      return number.isValid() ? number.number : phone;
    } catch {
      return phone;
    }
  };

  return { setFormatPhone, getFormatPhone, getPhone };
};

export const downloadPdf = (url: string, name: string = 'invoice.pdf') => {
  downloadGCSPdf(url)
    .then((response: any) => {
      const blob = new Blob([response.data]);
      const pdfUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click'));
      link.remove();
      URL.revokeObjectURL(pdfUrl);
    })
    .catch(console.error);
};

export const stringWithDot = (stringToBeCut: string, length = 7) =>
  stringToBeCut.length > length
    ? `${stringToBeCut.substring(0, length - 3)}...`
    : stringToBeCut;

export const capitalizeFirstLetterAndDash = (str: string) => {
  const words = str?.split('-');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const result = capitalizedWords.join('-');

  return result;
};

export const capitalizeFirstLetterSpace = (str: string) => {
  const words = str?.split(' ');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const result = capitalizedWords.join(' ');

  return result;
};

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result?.toString()?.split(',')[1];

      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });

export const getFormattedNumber = (
  number: any,
  isDecimal: boolean = true,
  isDisplayDollar = false,
  isParseInt = false
) => {
  var options: Intl.NumberFormatOptions = {};

  if (isDecimal) {
    options = Number.isInteger(number)
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  }

  if (isParseInt) {
    number = parseInt(number);
  }
  const formattedNumber = new Intl.NumberFormat('en-US', options).format(
    number
  );

  if (isDisplayDollar) {
    if (number >= 0) {
      return formattedNumber;
    } else {
      return `-$${formattedNumber.replace('-', '')}`;
    }
  }

  return formattedNumber;
};

export const setNumberWithCommas = (number: any) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatBigNumberToAbbreviation = (num: number) => {
  if (Math.abs(num) >= 1.0e9) {
    return `${(num / 1.0e9).toFixed(2).replace(/\.?0+$/, '')}B`;
  } else if (Math.abs(num) >= 1.0e6) {
    return `${(num / 1.0e6).toFixed(2).replace(/\.?0+$/, '')}M`;
  } else if (Math.abs(num) >= 1.0e3) {
    return `${(num / 1.0e3).toFixed(1).replace(/\.?0+$/, '')}K`;
  } else {
    return num.toString();
  }
};

export const DateFormat = (val: any) => {
  let formattedDate = '';

  if (val) {
    const date = new Date(val);
    formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  }

  return formattedDate;
};

export const getDateRange = (range: any, allTimeStartDate?: any) => {
  switch (range) {
    case 'last_7_days':
      return {
        start: moment().subtract(6, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_30_days':
      return {
        start: moment().subtract(29, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_90_days':
      return {
        start: moment().subtract(89, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_year':
      return {
        start: moment().subtract(1, 'year').toDate(),
        end: moment().toDate(),
      };
    case 'monthly':
      return {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('month').toDate(),
      };
    case 'weekly':
      return {
        start: moment().isoWeekday(0).startOf('week').toDate(),
        end: moment().isoWeekday(0).endOf('week').toDate(),
      };
    case 'quarterly':
      return {
        start: moment(moment().startOf('quarter'), 'YYYY-MM-DD').toDate(),
        end: moment(moment().endOf('quarter'), 'YYYY-MM-DD').toDate(),
      };
    case 'yearly':
      return {
        start: moment(moment().startOf('year'), 'YYYY-MM-DD').toDate(),
        end: moment(moment().endOf('year'), 'YYYY-MM-DD').toDate(),
      };
    case 'daily':
      return {
        start: moment().toDate(),
        end: moment().toDate(),
      };
    case 'all_time':
      return {
        start: moment(allTimeStartDate).toDate(),
        end: moment().toDate(),
      };
    // Add more cases for Weekly, Monthly, Quarterly, Yearly as needed
    default:
      return {};
  }
};

export const dateToDayAndTime = (dateString: any) => {
  const date = new Date(dateString);

  // const options = { weekday: "long", hour: "2-digit", minute: "2-digit" };
  return date.toLocaleString('en-US', {
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const calculateNiceMaximum = (maxDataValue: any, tickAmount: any) => {
  let rawStep = maxDataValue / tickAmount;
  let magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)));
  let normalizedStep = rawStep / magnitude;

  let adjustedStep;

  if (normalizedStep <= 1) {
    adjustedStep = 1;
  } else if (normalizedStep <= 2) {
    adjustedStep = 2;
  } else if (normalizedStep <= 5) {
    adjustedStep = 5;
  } else {
    adjustedStep = 10;
  }

  let customMax =
    Math.ceil(maxDataValue / (magnitude * adjustedStep)) *
    (magnitude * adjustedStep);
  customMax = Math.max(customMax, maxDataValue);

  return customMax;
};

export const convertBase64ToFile = (appLogoImage: any) => {
  try {
    const imageExtension = appLogoImage.substring(
      appLogoImage.indexOf('/') + 1,
      appLogoImage.indexOf(';base64')
    );

    const currentTimestamp = Date.now();
    const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
    const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

    const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

    const uint8Array = Uint8Array.from(atob(base64Data), (c) =>
      c.charCodeAt(0)
    );

    const blob = new Blob([uint8Array], {
      type: 'application/octet-stream',
    });

    const convertedFile = new File([blob], filename, {
      type: 'application/octet-stream',
    });

    return {
      convertedFile: convertedFile,
      filename: filename,
    };
  } catch (error) {
    // console.error('Error converting base64 to file:', error);
  }
};

export const checkFileTypeValidation = (uploadedFile: any, size: any) => {
  const validType = ['image/svg+xml', 'image/png', 'image/jpeg'];
  const validationResults = _.map(uploadedFile, (file: File) => {
    if (!validType.includes(file.type)) {
      return {
        result: false,
        message: 'Invalid file format. Please upload a valid image file.',
      };
    } else if (file.size > size) {
      return {
        result: false,
        message: `Image size must be less than ${Math.round(
          size / (1028 * 1028)
        )}MB`,
      };
    } else {
      return { result: true, message: '' };
    }
  });

  const validationResult = _.every(
    validationResults,
    (result: { result: boolean }) => result.result === true
  );

  return {
    result: validationResult,
    message: validationResult ? '' : validationResults[0].message,
  };
};

export const convertToGBMBKB = (val: any) => {
  const input = parseFloat(val);
  const gb = 1024 * 1024 * 1024; // Convert to gigabytes
  const mb = 1024 * 1024; // Convert to megabytes
  const kb = 1024; // Convert to kilobytes
  var convertedVal: any = 0;

  if (input > gb) {
    convertedVal = `${Math.round(val / gb)} Gb`;
  } else if (input > mb && input < gb) {
    convertedVal = `${Math.round(val / mb)} Mb`;
  } else if (input > kb && input < mb) {
    convertedVal = `${Math.round(val / kb)} Kb`;
  }

  return convertedVal;
};

export const TimeConverter = (inputDateTime: any) => {
  const momentDateTime = moment.utc(inputDateTime).tz(TIMEZONE);

  return momentDateTime.format('dddd hh:mm A');
};

export const getDateWithSuffixFormat = (date: any) => {
  const day = moment.utc(date, 'MMM Do YYYY').date();

  if (day >= 11 && day <= 13) {
    return date.replace('th', `<sup>th</sup> `);
  } else {
    switch (day % 10) {
      case 1:
        return date.replace('st', `<sup>st</sup> `);
      case 2:
        return date.replace('nd', `<sup>nd</sup> `);
      case 3:
        return date.replace('rd', `<sup>rd</sup> `);
      default:
        return date.replace('th', `<sup>th</sup> `);
    }
  }
};

export const getFormattedDate = (
  date: any,
  format = 'MMMM Do, YYYY',
  isTimeDisplay = false,
  convertTimezone = false
) => {
  // const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const momentDate = moment.utc(date).tz(browserTimezone);
  let momentDate = moment(date);

  if (convertTimezone) {
    momentDate = momentDate.utc(date).tz(TIMEZONE);
  }
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const tomorrow = moment().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    if (!isTimeDisplay) return `Today `;
    else return `Today, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    if (!isTimeDisplay) return `Yesterday`;
    else return `Yesterday, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    if (!isTimeDisplay) return `Tomorrow`;
    else return `Tomorrow, ${momentDate.format('hh:mm A')}`;
  } else {
    return momentDate.format(format);
  }
};

export const getLabelByValue = (value: any) => {
  const obj: any = MEASUREMENT;

  for (const key in obj) {
    if (obj[key].value === value) {
      return obj[key].label;
    }
  }

  return null;
};

export const formatAddress = (address: string | false) => {
  if (typeof address === 'string') {
    address = address.replace(/Ã©/g, 'é');
    address = address.replace(/Ã‰/g, 'É');
    address = address.replace(/%/g, '');
    address = address.replace(/Ã /g, 'à');
    address = address.replace(/Ã¨/g, 'è');
    address = address.replace(/Ã¬/g, 'ì');
    address = address.replace(/Ã²/g, 'ò');
    address = address.replace(/Ã¹/g, 'ù');
    address = address.replace(/Ã‚/g, 'Â');
    address = address.replace(/Ã‹/g, 'Ë');
    address = address.replace(/ÃŠ/g, 'Ê');
    address = address.replace(/Ã‹/g, 'Ë');
    address = address.replace(/Ã¯/g, 'ï');
    address = address.replace(/Ã´/g, 'ô');
    address = address.replace(/Ã¶/g, 'ö');
    address = address.replace(/Ã»/g, 'û');
    address = address.replace(/Ã¼/g, 'ü');
    address = address.replace(/Ã§Ã /g, 'ç');
    address = address.replace(/ÃŸ/g, 'ß');
    address = address.replace(/Ã¦/g, 'æ');
    address = address.replace(/Ã¸/g, 'ø');
    address = address.replace(/Ã¥/g, 'å');
    address = address.replace(/Ã¢/g, 'â');
    address = address.replace(/Ã‘/g, 'Ñ');
    address = address.replace(/Ã¡/g, 'á');
    address = address.replace(/Ã³/g, 'ó');

    return address;
  }

  return address;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str;
};

export const fetchJsFromCDN = (src: any, externals: any = []) =>
  new Promise((resolve, reject) => {
    document.getElementById('ioScript')?.remove();
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('id', 'ioScript');
    script.addEventListener('load', () => {
      resolve(
        externals.map((key: any) => {
          const ext = window[key];

          if (typeof ext === 'undefined') {
            console.warn(`No external named '${key}' in window`);
          }

          return ext;
        })
      );
    });
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });

export const isValidJSON = (str: any) => {
  try {
    JSON.parse(str);

    return true;
  } catch (e) {
    return false;
  }
};

// export const getProgressClass = (value: any) => {
//   if (value >= 75) return 'progress-success';
//   if (value >= 50) return 'progress-yellow';

//   return 'progress-danger';
// };

const subtractWorkingDays = (date = moment(), days = 1) => {
  let result = moment(date); // Start from the given date

  while (days > 0) {
    // Check if it's a weekday
    if (result.isoWeekday() !== 6 && result.isoWeekday() !== 7) {
      days--; // Decrease the working days count
    }

    result.subtract(1, 'days'); // Subtract one day
  }

  return result;
};

const calculateWorkingDays = () => {
  const startOfMonth = subtractWorkingDays(moment().startOf('month'), 5);
  const endOfMonth = subtractWorkingDays(moment().endOf('month'), 5);
  const today = moment().startOf('day'); // Start of today to exclude today

  let totalWorkingDays = 0;
  let passedWorkingDays = 0;

  for (
    let day = startOfMonth;
    day.isBefore(endOfMonth) || day.isSame(endOfMonth);
    day.add(1, 'day')
  ) {
    const dayOfWeek = day.day();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0 is Sunday, 6 is Saturday
      totalWorkingDays++;

      if (day.isBefore(today)) {
        passedWorkingDays++;
      }
    }
  }

  return { totalWorkingDays, passedWorkingDays };
};

export const getProgressClass = (
  currentCompletionPercentage: any,
  forProgressBar = true,
  targetPercentage: any = 100
) => {
  const { totalWorkingDays, passedWorkingDays } = calculateWorkingDays();

  const requiredDailyProgress = targetPercentage / totalWorkingDays;
  const requiredCompletionToDate = requiredDailyProgress * passedWorkingDays;

  let assessment;

  if (currentCompletionPercentage >= requiredCompletionToDate) {
    assessment = 100;
  } else {
    assessment = (currentCompletionPercentage / requiredCompletionToDate) * 100;
  }

  if (assessment >= 80 && assessment <= 100)
    return forProgressBar ? 'progress-success' : 'text-fgSuccessPrimary';
  if (assessment >= 60 && assessment < 80)
    return forProgressBar ? 'progress-yellow' : 'text-yellow300';

  return forProgressBar ? 'progress-danger' : 'text-fgErrorPrimary';
};

export const customFromNowWithAgo = (date: any) => {
  const dateTime = moment.utc(date);
  const now = moment();
  const duration = moment.duration(now.diff(dateTime));

  if (now.isBefore(dateTime)) {
    return 'in the future';
  }

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = Math.abs(duration.seconds());

  let result = '';

  if (years > 0) {
    result = `${years}y ago`;
  } else if (months > 0) {
    result = `${months}mo ago`;
  } else if (days > 0) {
    result = `${days}d ago`;
  } else if (hours > 0) {
    result = `${hours}h ago`;
  } else if (minutes > 0) {
    result = `${minutes}m ago`;
  } else {
    result = `${seconds}s ago`;
  }

  return result.trim();
};

export const customFromNow = (dateTime: any) => {
  const duration = moment.duration(moment().diff(moment(dateTime)));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (years > 0) {
    result = `${years}yr ago`;
  } else if (months > 0) {
    result = `${months}mo ago`;
  } else if (days > 0) {
    result = `${days}d ago`;
  } else if (hours > 0) {
    result = `${hours}hr ago`;
  } else if (minutes > 0) {
    result = `${minutes}min ago`;
  } else {
    result = `${seconds}sec ago`;
  }

  return result.trim();
};

export const confettiAnimation = (count = 200) => {
  const defaults = {
    origin: { y: 0.7 },
  };

  function fire(particleRatio: any, opts: any) {
    window.confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });

  fire(0.2, {
    spread: 60,
  });

  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });

  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });

  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

const getTimeDifference = (givenDate: any) => {
  const now = new Date() as any;
  const pastDate = new Date(givenDate) as any;
  const differenceInMs = now - pastDate;

  const differenceInSeconds = Math.floor(differenceInMs / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);

  const years = Math.floor(differenceInHours / (24 * 365));
  const months = Math.floor(differenceInHours / (24 * 30)) % 12;
  const days = Math.floor(differenceInHours / 24) % 30;
  const hours = differenceInHours % 24;
  const minutes = differenceInMinutes % 60;
  const seconds = differenceInSeconds % 60;

  return { years, months, days, hours, differenceInHours, minutes, seconds };
};

function formatTimeUnit(unit: any) {
  return unit < 10 ? `0${unit}` : unit;
}

export const displayTimeDifference = (givenDate: any): any => {
  const { years, months, days, differenceInHours, minutes, seconds } =
    getTimeDifference(givenDate);

  let displayTime;

  if (differenceInHours < 100) {
    displayTime = `${formatTimeUnit(differenceInHours)}:${formatTimeUnit(
      minutes
    )}:${formatTimeUnit(seconds)}`;
  } else if (days > 0) {
    displayTime = `${days}d ago`;
  } else if (months > 0) {
    displayTime = `${months}m ago`;
  } else if (years > 0) {
    displayTime = `${years}y ago`;
  }

  return displayTime;
};

export const getRankDiamond = (ordCount: number) => {
  if (ordCount <= 4) {
    return Bronze;
  } else if (ordCount <= 16) {
    return Silver;
  } else if (ordCount <= 32) {
    return Gold;
  } else if (ordCount <= 100) {
    return Platinum;
  } else {
    return Diamond;
  }
};

export const shuffleArray = (array: any) =>
  array
    .map((a: any) => ({ sort: a.score, value: a }))
    .sort((a: any, b: any) => b.sort - a.sort)
    .map((a: any) => a.value);

export const loadGoogleMapsScript = (googleKey: string, scriptId: string) => {
  // Prevent multiple script loads
  if (document.getElementById(scriptId)) {
    console.log('Google Maps script already exists in DOM');

    return;
  }

  // If Google Maps is already available, just trigger callback
  if (window.google?.maps?.places) {
    console.log('Google Maps already loaded');

    if (typeof window.initMap === 'function') {
      window.initMap();
    }

    return;
  }

  // Make sure initMap is defined before loading script
  if (!window.initMap) {
    window.initMap = function () {
      console.log('Maps initialization from utility function');
    };
  }

  const script = document.createElement('script');
  script.id = scriptId;
  // Include the visualization library which contains the StyledMapType feature
  script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places,visualization&callback=initMap`;
  script.async = true;
  script.defer = true;

  script.onerror = function () {
    console.error('Failed to load Google Maps script');
  };

  document.head.appendChild(script);
};
