import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { BasicContext } from 'src/context/BasicContext';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { lastSync } from 'src/services/SalesDashboard';
import WalToast from 'src/utils/WalToast';

import Forbidden from '../app/Errors/403';
import { APP_FULL_NAME } from '../constants/common';
import { ROUTES } from '../constants/routes';
import { AuthContext } from '../context/AuthContext';
import { fetchJsFromCDN, useRolePermission } from '../utils/CommonFunctions';

const PrivateRoute = ({
  component: Component,
  title,
  permission,
  ...props
}: any) => {
  const { hasPermissionV2 } = useRolePermission();
  const { isAuthenticated } = useContext(AuthContext);
  const { setLastSyncDateTimeActual, setAllTimeStartDateActual } =
    useContext(BasicContext);

  const dispatch = useDispatch();
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  useEffect(() => {
    document.title = `${title} - ${APP_FULL_NAME}`;
  }, [title]);

  const handleLastSyncTimeUpdateData = (data: any) => {
    // console.log('GOT USER SOCKET DATA ', data);

    if (data?.data?.firstOrderDate && data?.data?.lastSyncTime) {
      setLastSyncDateTimeActual(data?.data?.lastSyncTime);
      setAllTimeStartDateActual(data?.data?.firstOrderDate);
    }
  };

  const handleLeaderboardRankChangeNotification = (notificationData: any) => {
    WalToast.success(
      notificationData.name ?? 'Incentive ranks updated!',
      notificationData.message
    );
  };

  const connectSocket = () => {
    console.log(
      'connectSocket() called',
      'isSocketConnected:',
      isSocketConnected,
      '!!socketIO?.on',
      !!socketIO?.on
    );

    if (isSocketConnected && !!socketIO?.on) {
      console.log('remove all listeners and add global ones');

      socketIO.removeAllListeners();

      socketIO.on('connect', function socketConnected() {
        socketIO.get(`/subscribe/globalRoom`, function (data: any, jwr: any) {
          if (jwr.error) {
            return;
          }
        });
      });

      socketIO.on('lastSyncTimeUpdated', function (data: any) {
        handleLastSyncTimeUpdateData(data);
      });

      socketIO.on('leaderboard_ranks', function (data: any) {
        handleLeaderboardRankChangeNotification(data);
      });
    } else {
      console.log('establish new connection from script');

      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;
          io.socket.on('connect', function socketConnected() {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          io.socket.on('lastSyncTimeUpdated', function (data: any) {
            handleLastSyncTimeUpdateData(data);
          });

          io.socket.on('leaderboard_ranks', function (data: any) {
            handleLeaderboardRankChangeNotification(data);
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('leaderboard-new-activity');
          };
        })
        .catch((error: any) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  };

  useEffect(() => {
    console.log('something changed');

    if (isAuthenticated) {
      connectSocket();
    }

    return () => {
      if (socketIO?.on) {
        socketIO.removeAllListeners();
      }
    };
  }, [socketIO, isSocketConnected]);

  useEffect(() => {
    if (isAuthenticated) {
      lastSync().then((response: any) => {
        setLastSyncDateTimeActual(response?.lastSyncTime);
        setAllTimeStartDateActual(response?.firstOrderDate);
      });
    }
  }, []);

  return isAuthenticated ? (
    <div className="md:ml-[var(--layout-sidebar-width)] bg-utilityGray300 overflow-hidden flex flex-col h-full">
      <p className="pt-[var(--layout-top-padding)]"></p>
      <div className="flex-grow overflow-y-auto h-full">
        {hasPermissionV2(permission) || permission === true ? (
          <Component {...props} />
        ) : (
          <Forbidden />
        )}
      </div>
    </div>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};

export default PrivateRoute;
