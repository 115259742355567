import {} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import TabButtonLeaderboard from 'src/components/TabButtonLeaderboard';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import {
  capitalizeFirstLetter,
  getShortName,
  isValidJSON,
} from 'src/utils/CommonFunctions';

import kingOfTheHill from '../../../assets/img/king-of-the-hill.png';

const filterArr = [
  {
    value: 'details',
    name: 'Details',
  },
  {
    value: 'activity',
    name: 'Activity',
  },
];

const LeaderBoardMetaDetails = ({
  isLoading,
  leaderBoardEventDetail,
  leaderBoardActivities,
}: any) => {
  const messageRefs = useRef<(HTMLParagraphElement | null)[]>([]);
  const tieBreakerMapping: any = { gross_profit: 'Gross Profit' };
  const [remainingDays, setRemainingDays] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.LEADERBOARD_EVENT_TAB);

    return storedParams &&
      isValidJSON(storedParams) &&
      JSON.parse(storedParams)?.activeTab
      ? JSON.parse(storedParams)?.activeTab
      : 'details';
  });

  useEffect(() => {
    localStorage.setItem(
      TABLE_IDS.LEADERBOARD_EVENT_TAB,
      JSON.stringify({ activeTab: activeTab })
    );
  }, [activeTab]);

  const handleClick = (custId: number) => {
    window.open(`${PATH.CUSTOMER_DETAILS}/${custId}`, '_blank');
  };

  useEffect(() => {
    setTimeout(() => {
      if (leaderBoardActivities?.length > 0) {
        leaderBoardActivities.forEach((activity: any, index: number) => {
          if (activity) {
            const spanElement = document.getElementById(
              `clickable-customer-link-${activity?.id}`
            );

            if (spanElement) {
              spanElement.addEventListener('click', () =>
                handleClick(leaderBoardActivities[index]?.custId)
              );
            }
          }
        });

        return () => {
          leaderBoardActivities.current.forEach(
            (activity: any, index: number) => {
              if (activity) {
                const spanElement = document.getElementById(
                  `clickable-customer-link-${activity?.id}`
                );

                if (spanElement) {
                  spanElement.removeEventListener('click', () =>
                    handleClick(leaderBoardActivities[index]?.custId)
                  );
                }
              }
            }
          );
        };
      }
    }, 100);
  }, [leaderBoardActivities, activeTab]);

  const customFromNowTimeWithAgo = (createdAt: any) => {
    const dateTime = moment.utc(createdAt, 'YYYY-MM-DD HH:mm:ss');
    const now = moment.utc();
    const duration = moment.duration(now.diff(dateTime));

    if (now.isBefore(dateTime)) {
      return 'Just now';
    }

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = Math.abs(duration.seconds());

    let result = '';

    if (years > 0) {
      result = `${years}y ago`;
    } else if (months > 0) {
      result = `${months}mo ago`;
    } else if (days > 0) {
      result = `${days}d ago`;
    } else if (hours > 0) {
      result = `${hours}h ago`;
    } else if (minutes > 0) {
      result = `${minutes}m ago`;
    } else {
      result = `${seconds}s ago`;
    }

    return result.trim();
  };

  const checkRemainingDays = () => {
    let eventEndAt6PM = moment(leaderBoardEventDetail.end_event)
      .tz('America/New_York')
      .set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
    let now = moment().tz('America/New_York');
    const duration = moment.duration(eventEndAt6PM.diff(now));
    setRemainingDays(Math.floor(duration.asDays()));
  };

  useEffect(() => {
    if (leaderBoardEventDetail) {
      checkRemainingDays();
      const interval = setInterval(() => {
        checkRemainingDays();
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [leaderBoardEventDetail]);

  return (
    <>
      <div className="pb-4 border-b border-utilityGray200">
        <h6 className="text-grayLight900 text-lg font-semibold">
          {activeTab === 'details' && 'Competition '}
          {capitalizeFirstLetter(activeTab)}
        </h6>
        <p className="text-grayLight600 text-xs font-normal mb-4">
          Live Incentive updates
        </p>
        <TabButtonLeaderboard
          parentClassName={`w-full`}
          className="flex-1"
          tabArray={filterArr}
          isActive={activeTab}
          isTab={true}
          handleOnClick={(e: any) => {
            setActiveTab(e?.target?.dataset?.value);
          }}
        />
      </div>

      {/* DETAIL TAB CONTENT BELOW */}
      {activeTab === 'details' && (
        <ul className="flex-1 overflow-auto custom-scrollbar-v2 pr-2 space-y-4">
          <li
            className={`rounded-lg border border-utilityGray200 bg-gray25 p-3 space-y-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            <h6 className={`text-utilityGray800 text-sm font-medium `}>
              {leaderBoardEventDetail?.event_title}
            </h6>
            {/* <p
              className={`text-grayLight600 text-xs font-normal ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {leaderBoardEventDetail.event_description}
            </p> */}
            <div className={`text-grayLight600 text-xs font-normal `}>
              <p>
                Senior Account Executives (AEs) compete to acquire the most new
                customers from Monday, February 3rd at 9:00 AM to Friday, March
                14th at 5:00 PM. The objective is to earn the most points by the
                end of the competition. Points can be earned in three ways:
              </p>
              <ul className="list-disc pl-5 my-2.5 space-y-1.5">
                <li>
                  <span className="font-semibold text-utilityGray800">
                    Hill Leader Bonus:
                  </span>{' '}
                  The AE with the most new customers at the end of each day
                  earns{' '}
                  <span className="font-medium text-utilityGray800">
                    100 points.
                  </span>
                </li>
                <li>
                  <span className="font-semibold text-utilityGray800">
                    New Customer Acquisition:
                  </span>{' '}
                  Each new customer acquired earns{' '}
                  <span className="font-medium text-utilityGray800">
                    50 points.
                  </span>
                </li>
                <li>
                  <span className="font-semibold text-utilityGray800">
                    Gross Profit Bonus:
                  </span>{' '}
                  Every{' '}
                  <span className="font-medium text-utilityGray800">
                    $1,000 in Gross Profit (GP) delivered
                  </span>{' '}
                  from new customers earns{' '}
                  <span className="font-medium text-utilityGray800">
                    50 points.
                  </span>
                </li>
              </ul>
              <p>
                The leaderboard is updated continuously throughout the
                competition.
              </p>
            </div>
          </li>

          <li
            className={`rounded-lg border border-utilityGray200 bg-gray25 p-3 space-y-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            <h6 className="text-utilityGray800 text-sm font-medium">
              Attachments
            </h6>
            <div className={`flex items-end gap-x-2.5`}>
              <img
                src={kingOfTheHill}
                className="w-20 object-contain rounded-lg"
              />
              <p className="text-grayLight600 text-xs font-normal mt-0.5">
                King of the Hill
              </p>
            </div>
          </li>
          <li
            className={`rounded-lg border border-utilityGray200 bg-gray25 p-3 space-y-2 flex items-center justify-between ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            <div>
              <h6 className="text-utilityGray800 text-sm font-medium">
                Ends on
              </h6>
              <div
                className={`flex items-end gap-x-2.5 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                <p className="text-grayLight600 text-xs font-normal mt-0.5">
                  {leaderBoardEventDetail?.end_event
                    ? moment
                        .utc(leaderBoardEventDetail.end_event)
                        .tz('America/New_York')
                        .format('MMMM Do, YYYY, hh:mm A')
                    : ''}
                </p>
              </div>
            </div>
            <div className="text-center bg-[#F2F4F7] px-1 py-1.5 mt-0 rounded-lg">
              <h5 className="text-fgErrorPrimary">
                {!moment
                  .utc()
                  .isAfter(moment.utc(leaderBoardEventDetail?.end_event))
                  ? remainingDays
                  : ''}
              </h5>
              <p className="text-grayLight600 text-xs font-normal mt-0.5">
                {!moment
                  .utc()
                  .isAfter(moment.utc(leaderBoardEventDetail?.end_event))
                  ? 'DAYS'
                  : 'Completed'}
              </p>
            </div>
          </li>

          <li
            className={`rounded-lg border border-utilityGray200 bg-gray25 p-3 space-y-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            <h6 className="text-utilityGray800 text-sm font-medium">Prize</h6>
            {/* <p
              className={`text-grayLight600 text-xs font-normal ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {leaderBoardEventDetail.first_prize} for winner,{' '}
              {leaderBoardEventDetail.second_prize} second,{' '}
              {leaderBoardEventDetail.third_prize} third
            </p> */}
            <p className="text-grayLight600 text-xs font-normal">
              The AE with the most points at the end of the competition will win
              a ticket to the 2026 Costa Rica wellness trip.
            </p>
          </li>

          <li
            className={`rounded-lg border border-utilityGray200 bg-gray25 p-3 space-y-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            <h6 className="text-utilityGray800 text-sm font-medium">
              Tie Breaker
            </h6>
            <p
              className={`text-grayLight600 text-xs font-normal hidden ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {tieBreakerMapping[leaderBoardEventDetail?.tie_breaker]}
            </p>
            <p className="text-grayLight600 text-xs font-normal">
              In case of a tie, the AE with the highest total Gross Profit (GP)
              from new customers acquired during the competition will be
              declared the winner.
            </p>
          </li>

          <li
            className={`rounded-lg border border-utilityGray200 bg-gray25 p-3 space-y-2 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            <h6 className="text-utilityGray800 text-sm font-medium">Rules</h6>
            {leaderBoardEventDetail?.event_rules?.length > 0 ? (
              <>
                <ul className="list-disc pl-5 space-y-1.5 text-grayLight600 text-xs font-normal">
                  <li>
                    Only{' '}
                    <span className="font-semibold text-utilityGray800">
                      new customers
                    </span>{' '}
                    acquired within the competition period will be counted.
                  </li>
                  <li>
                    To qualify,{' '}
                    <span className="font-semibold text-utilityGray800">
                      new customer loads must have a pickup date before March
                      21, 2025.
                    </span>
                  </li>
                  <li>
                    If an AE has more than{' '}
                    <span className="font-semibold text-utilityGray800">
                      two unjustified cancellations,
                    </span>{' '}
                    they will be disqualified.
                  </li>
                </ul>
                {/* <ul className="list-disc pl-6">
                  {leaderBoardEventDetail.event_rules.map(
                    (rule: any, index: React.Key) => (
                      <li
                        key={index}
                        className="text-grayLight600 text-xs font-normal"
                      >
                        {rule ?? '-'}
                      </li>
                    )
                  )}
                </ul> */}
              </>
            ) : (
              <p className="text-grayLight600 text-xs font-normal mt-0.5">-</p>
            )}
          </li>
        </ul>
      )}

      {/* ACTIVITY TAB CONTENT BELOW */}
      {activeTab === 'activity' && (
        <ul className="flex-1 overflow-auto custom-scrollbar-v2 pr-2 space-y-4">
          {leaderBoardActivities?.length > 0 ? (
            leaderBoardActivities?.map((activity: any, index: number) => (
              <li key={index} className="flex gap-x-3">
                <div className="flex-none">
                  {activity.profileImage ? (
                    <img
                      className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      src={activity.profileImage}
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
                      <span>{getShortName(`${activity.name}`)}</span>
                    </div>
                  )}
                </div>
                <div>
                  <div className="flex items-center">
                    <h6 className="text-textSecondary text-sm font-medium line-clamp-1">
                      {activity.name}
                    </h6>
                    <span className="pl-2 flex-none text-grayLight600 text-xs text-normal">
                      {activity?.createdAt
                        ? customFromNowTimeWithAgo(activity?.createdAt)
                        : ''}
                    </span>
                  </div>
                  <p
                    ref={(el) => (messageRefs.current[index] = el)}
                    className="text-grayLight600 text-xs font-normal "
                    dangerouslySetInnerHTML={{
                      __html: activity?.message
                        ? activity?.message.replace(
                            new RegExp(`@${activity?.customerName}`, 'g'),
                            `<span class="text-primary700 cursor-pointer" id="clickable-customer-link-${activity?.id}">@${activity?.customerName}</span>`
                          )
                        : '',
                    }}
                  />
                </div>
                {/* <p className="w-2 h-2 bg-primary500 rounded-full flex-none"></p> */}
              </li>
            ))
          ) : isLoading ? (
            [...Array(20)].map((_, index) => (
              <li className="flex gap-x-3" key={index}>
                <div className="flex-none">
                  <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-sm custom-loading">
                    <span>JS</span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <h6 className="text-textSecondary text-sm font-medium line-clamp-1">
                      <span className="custom-loading">Dev User</span>
                    </h6>
                    <span className="pl-2 flex-none text-grayLight600 text-xs text-normal">
                      <span className="custom-loading">2m ago</span>
                    </span>
                  </div>
                  <p className="text-grayLight600 text-xs font-normal custom-loading">
                    <span className="custom-loading">
                      Earned 100 points for onboarding test customer
                    </span>
                  </p>
                </div>
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      )}
    </>
  );
};

export default LeaderBoardMetaDetails;
