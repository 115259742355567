import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';

import ButtonCmp from '../ButtonCmp';

interface IPaginationProps {
  recordsPerPage: number;
  totalRecords: number;
  currentPage: number;
  handlePagination: (pageNumber: number) => void;
  nextLabelText?: string;
  prevLabelText?: string;
  previousClassName?: string;
  nextClassName?: string;
  isShowArrowAlways?: boolean;
}

const CustomPagination = ({
  recordsPerPage = 10,
  totalRecords = 0,
  currentPage = 1,
  previousClassName,
  nextClassName,
  handlePagination,
  nextLabelText = 'Next',
  prevLabelText = 'Previous',
  isShowArrowAlways,
}: IPaginationProps) => {
  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    setTotalPageCount(Math.ceil(totalRecords / recordsPerPage));
  }, [currentPage, totalRecords, recordsPerPage]);

  return (
    <div
      className="w-full flex justify-between items-center wal-pagination relative border-t border-utilityGray200 py-3 px-6"
      data-test-id="wal-QuotingDashboard-paginationNumber"
    >
      <div className="text-textSecondary text-sm font-medium">
        Page {currentPage} of {totalPageCount}
      </div>
      <div className="flex gap-3">
        <ButtonCmp
          disabled={currentPage === 1}
          className={`btn_secondary_black shadow btn-heigh-auto lg:px-[9px] px-2 ${previousClassName}`}
          onClick={() => {
            handlePagination(currentPage - 1);
          }}
        >
          {
            <ArrowLeft
              className={`w-4 h-4 ${isShowArrowAlways ? '' : 'sm:hidden'}`}
            />
          }
          {prevLabelText && (
            <span className="max-sm:hidden">{prevLabelText}</span>
          )}
        </ButtonCmp>
        <ButtonCmp
          disabled={
            currentPage === totalPageCount || currentPage > totalPageCount
          }
          className={`btn_secondary_black shadow btn-heigh-auto ${nextClassName} ${
            isShowArrowAlways ? 'lg:px-[9px] px-2' : '[&>svg]:sm:hidden'
          }`}
          onClick={() => {
            handlePagination(currentPage + 1);
          }}
        >
          {nextLabelText && (
            <span className="max-sm:hidden">{nextLabelText}</span>
          )}
          {<ArrowRight className={`w-4 h-4 `} />}
        </ButtonCmp>
      </div>
    </div>
  );
};

export default CustomPagination;
