import React, { useMemo, useState, useEffect } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import FreightStatisticsLoadingRow from './FreighStatisticsLoadingRow';
import FreightStatisticsRow from './FreightStatisticsRow';

interface RegionalAnalyticsData {
  category: string;
  num_of_loads: number;
  unique_customers: number;
  gross_profit: number;
}

interface IProps {
  regionalAnalyticsData: RegionalAnalyticsData[];
  isDataLoading: boolean;
}

const FreightStatisticsTable = ({
  regionalAnalyticsData,
  isDataLoading,
}: IProps) => {
  const [filteredData, setFilteredData] = useState<RegionalAnalyticsData[]>([
    ...regionalAnalyticsData,
  ]);
  const [sortData, setSortData] = useState({
    sortField: 'category',
    sortType: 'asc',
  });

  const FreightHeadCells = useMemo(
    () => [
      {
        id: 'category',
        name: 'Name',
        sortable: true,
      },
      {
        id: 'num_of_loads',
        name: 'No. of Loads',
        sortable: true,
      },
      {
        id: 'unique_customers',
        name: 'No. of Customers',
        sortable: true,
      },
      {
        id: 'gross_profit',
        name: 'Gross Profit',
        sortable: true,
      },
    ],
    []
  );

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedData = [...regionalAnalyticsData].sort((a: any, b: any) => {
      if (sortField === 'category') {
        const valueA = a[sortField].toUpperCase();
        const valueB = b[sortField].toUpperCase();

        return sortType === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });
    setFilteredData(sortedData);
  };

  useEffect(() => {
    setFilteredData([...regionalAnalyticsData]);
    handleSorting();
  }, [regionalAnalyticsData]);

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  return (
    <>
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Regional Freight Statistics</h5>
            </div>
            <p className="table-subtitle">
              Comprehensive Analysis of Regional Freight Sales Data and
              Performance Trends
            </p>
          </div>
        </div>
        <div className=" w-full flex flex-col">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={FreightHeadCells}
              tableDataArr={isDataLoading ? [] : filteredData}
              TableLoaderRowCmp={FreightStatisticsLoadingRow}
              TableRowCmp={FreightStatisticsRow}
              numberOfSkeletonRows={4}
              isTableDataLoading={isDataLoading}
              params={sortData}
              setParams={setSortData}
            />
          </div>

          {regionalAnalyticsData.length <= 0 && !isDataLoading && (
            <NotFoundUI
              title={'No Freight Statistics Data found'}
              desc={`No freight statistics data available at the moment.`}
              containerClassName="!h-auto"
              iconClassName="!hidden"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FreightStatisticsTable;
