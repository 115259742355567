export const APP_ROOT = '/';

export const ROUTES = {
  HOME: `${APP_ROOT}`,
  LOGIN: `${APP_ROOT}login`,
  CALLBACK: `${APP_ROOT}callback`,
  TEAMS: `${APP_ROOT}teams`,
  CARRIERS: `${APP_ROOT}carriers`,
  CONNECTIONS: `${APP_ROOT}connections`,
  CUSTOMERS: `${APP_ROOT}customers`,
  INTEGRATION: `${APP_ROOT}integrations`,
  CUSTOMER_DETAILS: `${APP_ROOT}customers/:id?`,
  TEAM_DETAIL: `${APP_ROOT}team-management/:id?`,
  ADDRESS_DETAILS: `${APP_ROOT}quotes/dashboard/address-details/:id?`,
  DIMENSIONS: `${APP_ROOT}quotes/dashboard/dimensions/:id?`,
  MY_QUOTE: `${APP_ROOT}quotes/dashboard/:id?`,
  PREDICTIVE_PRICING: `${APP_ROOT}predictive-pricing/`,
  FEEDBACK_LIST: `${APP_ROOT}feedback`,
  ACCOUNT_SETTING: `${APP_ROOT}account-setting`,
  ORDERS: `${APP_ROOT}orders`,
  QUOTING_DASHBOARD: `${APP_ROOT}quotes/dashboard`,
  QUOTING_REQUEST: `${APP_ROOT}quotes/request`,
  REQ_SMART_QUOTE: `${APP_ROOT}quotes/request/address-details/:id?`,
  REQ_DIMENSION: `${APP_ROOT}quotes/request/dimensions/:id?`,
  REQ_MY_QUOTE: `${APP_ROOT}quotes/request/:id?`,
  LANE_HISTORY: `${APP_ROOT}lane-history`,
  RATE_SHOPPING: `${APP_ROOT}rate-shopping`,
  SALES_OVERVIEW: `${APP_ROOT}sales/overview`,
  SALES_HISTORY: `${APP_ROOT}sales/backup`,
  SALES_TARGET: `${APP_ROOT}sales/target`,
  SALES_TARGET_EDIT: `${APP_ROOT}sales/set-target`,
  TEAM_MANAGEMENT: `${APP_ROOT}team-management`,
  TEAM_MANAGEMENT_DETAIL: `${APP_ROOT}team-management/:id?`,
  ORGANIZATIONAL_CHART: `${APP_ROOT}organizational-chart`,
  TRACK_AND_TRACE: `${APP_ROOT}track-and-trace`,
  MAPIT: `${APP_ROOT}map-it`,
  LOAD_INSURANCE: `${APP_ROOT}load-insurance`,
  LOAD_INSURANCE_DETAILS: `${APP_ROOT}load-insurance/:id?`,
  INCENTIVE: `${APP_ROOT}incentive`,
};
