import React from 'react';

import CanadaFlagIcon from '../../../../assets/img/canada-square-icon.svg';
import USFlagIcon from '../../../../assets/img/us-square-icon.svg';

interface FreightStatisticsRowProps {
  data: {
    category: string;
    num_of_loads: number;
    unique_customers: number;
    gross_profit: number;
  };
}

const FreightStatisticsRow = ({ data }: FreightStatisticsRowProps) => (
  <>
    <td className="px-5 py-4 min-w-[25%] w-[25%] max-w-px">
      <div className="flex items-center gap-x-6 ">
        <div className="flex-none relative">
          <div className="w-8 h-8 rounded-full border border-utilityGray200 bg-utilityGray100">
            {data.category === 'Canada Domestic' ||
            data.category === 'Inbound to Canada' ? (
              <img
                src={CanadaFlagIcon}
                alt="canada"
                className="bject-contain "
              />
            ) : (
              <img src={USFlagIcon} alt="canada" className="bject-contain " />
            )}
          </div>
          <div className="absolute -bottom-[8px] -right-[12px] w-[24px] h-[24px] rounded-full border border-utilityGray200 bg-white">
            {data.category === 'USA Domestic' ||
            data.category === 'Inbound to Canada' ? (
              <img src={USFlagIcon} alt="canada" className="object-contain" />
            ) : (
              <img
                src={CanadaFlagIcon}
                alt="canada"
                className="bject-contain "
              />
            )}
          </div>
        </div>
        <p className="truncate text-sm font-semibold max-w-[calc(100%_-_56px)]">
          {data.category}
        </p>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[25%] w-[25%] max-w-[25%]">
      <span>{data.num_of_loads.toLocaleString()}</span>
    </td>
    <td className="px-5 py-4 min-w-[25%] w-[25%] max-w-[25%]">
      <span>{data.unique_customers.toLocaleString()}</span>
    </td>
    <td className="px-5 py-4 min-w-[25%] w-[25%] max-w-[25%]">
      <span>
        $
        {data.gross_profit.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </span>
    </td>
  </>
);

export default FreightStatisticsRow;
